//
//
//
//
//
//

import SharePriceAlertComponent from '@/components/InvestorRelations/SharePriceAlert.vue'
export default {
    name: 'SharePriceAlert',
    components: {
        SharePriceAlertComponent
    },
    data() {
        return {
            info: null
        }
    },
    mounted() {
        setTimeout(function () {
        }, 100)
    }
};
