//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SharePriceAlertunSubscribtionOrModification from '@/components/InvestorRelations/SharePriceAlertunSubscribtionOrModification.vue'
export default {
    name: 'SharePriceAlertComponent',
    components: {
        SharePriceAlertunSubscribtionOrModification
    },
    data: function () {
        let self = this;
        return {
            dictionary: {
                en: {
                    messages: {
                        required: function required(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('required');
                        },
                        email: function email() {
                            return self.$t('invalidEmailFormat');
                        },
                        max: function max(fieldName, numbers) {
                            return self.$t(fieldName) + self.$t('max') + " " + numbers + " " + self.$t('numbers');
                        }, min: function min(fieldName, numbers) {

                            return self.$t(fieldName) + " " + self.$t('min') + " " + numbers + " " + self.$t('numbers');
                        },
                        alpha: function alpha_spaces(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('alphaSpaces');
                        },
                        //between: function between(fieldName, n1, n2) {
                        //  return self.$t(fieldName) + ' ' + n1 + ' ' + n2;
                        //},
                        confirmed: function confirmed(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotConfirmed');
                        },
                        regex: function regex(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotMatchPasswordPattern');
                        },

                        between: function between(fieldName, n) {
                            return self.$t(fieldName) + ' ' + ' Should be Between ' + n[0] + ' and ' + n[1];
                        },
                        numeric: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('should be Number');
                        },
                        verify_password: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('ComplexPassword');
                        },
                        mobileReg: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('Number');
                        }
                    }
                },
                ar: {
                    messages: {
                        required: function required(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('required');
                        },
                        email: function email() {
                            return self.$t('invalidEmailFormat');
                        },
                        max: function max(fieldName, numbers) {
                            return self.$t(fieldName) + self.$t('max') + numbers + self.$t('numbers');
                        }, min: function min(fieldName, numbers) {

                            return self.$t(fieldName) + " " + self.$t('min') + " " + numbers + " " + self.$t('numbers');
                        },
                        alpha: function alpha_spaces(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('shouldBeCharOnly');
                        },
                        //between: function between(fieldName, n) {
                        //     return self.$t(fieldName) + self.$t('min') + n[0] + self.$t('max') + n[1];
                        //},
                        confirmed: function confirmed(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotConfirmed');
                        },
                        regex: function regex(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotMatchPasswordPattern');
                        },
                        between: function between(fieldName, n) {
                            return self.$t(fieldName) + ' ' + '??? ?? ???? ??? ' + n[0] + '?' + n[1];
                        },
                        numeric: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('should be Number');
                        },
                        verify_password: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('ComplexPassword');
                        },
                        mobileReg: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('Number');
                        }
                    }
                }

            },
            CountriesList: [],
            subscribeModel: {},
            showValidMessage: false,
            subscribeMsg: self.$t('subscribeSucess'),
            warnmesg: self.$t('warnmsg'),
            isActive: true,
        }
    },
    methods: {
        notify: function (message, fn = "error") {
            let self = this;
            self.$snotify.clear();
            if (fn == "success") {
                self.$snotify.success(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else if (fn == "warning") {
                self.$snotify.warning(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else {
                self.$snotify.html(message, {
                    type: "error",
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
        },
        onChange: function (isChecked, key) {
            debugger;
            var self = this;
            if (!isChecked) {
                switch (key) {
                    case 'PriceTarget':
                        self.model.PriceTarget = '';
                        break;
                    case 'StopLoss':
                        self.model.StopLoss = '';
                        break;
                    case 'Variation':
                        self.model.Variation = '';
                        break;
                    case 'VolumeTarget':
                        self.model.VolumeTarget = '';
                        break;
                    default:
                    // code block
                }
            }
        },
        loadLookups: function () {
            Promise.all([this.loadCountries()]);
        },

        loadCountries: function () {
            var self = this;
            return new Promise(function (resolve, reject) {
                self.axios.get(process.env.VUE_APP_APIEndPoint + "Country/PublicGetAll")
                    .then(function (res) {
                        self.CountriesList = res.data.Data;
                        resolve("Success!");
                    })
                    .catch(function (err) {
                        return console.log(err);
                    }).finally(function () {
                        return;
                    });
            });
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        Subscribe: function () {
            debugger;
            var self = this;
            self.showValidMessage = true;
            self.$validator.validate().then(function (result) {
                if (!result) { ////not valid
                    let detail = $('.form-error').first()[0];
                    if (detail) {
                        detail.scrollIntoView({ behavior: 'smooth', block: 'end' });
                        self.notify(self.warnmesg, "warning");

                    }
                    return;
                }
                $('.form-error').removeClass("form-error");

                self.axios
                    .post(process.env.VUE_APP_APIEndPoint + "SharePriceAlert/Subscribe", self.subscribeModel)
                    .then(function (res) {
                        if (res.data.IsSuccess)
                           
                            self.notify(self.$t('subscribeSucess'), "success");
                        else 
                            self.notify(self.$t(res.data.Messages[0].Message), "error");

                        self.scrollToTop();
                    })
                    .catch(function (err) {
                        self.displayError(err);
                    }).finally(function () {
                    })
            });
        }
        ,ConvertToJson: function (str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return "";
            }
            return JSON.parse(str);
        },
        displayError: function (err) {
            var self = this;
            if (err?.response?.status == "401") {
                return;
            }
            if (err?.response?.data?.type && err?.response?.data?.type.toLowerCase() == "business") {
                //let messages = err?.response?.data?.message;
                var messages = [];
                messages.push(self.ConvertToJson(err?.response?.data?.message));
                if (messages != "") {
                    let errorstr = "";
                    messages.map(x => errorstr += self.$t(x.MemberNames) + ' ' + self.$t(x.ErrorMessage) + "<br/>");
                    self.notify(errorstr, "error")
                }
                else {
                    self.notify(self.$t("ConnectionError"), "error");
                }
            }
            else if (err?.response?.data?.type && err?.response?.data?.type.toLowerCase() == "error") {
                var errorMsg = err?.response?.data?.message;
                self.notify(self.$t(errorMsg[Object.keys(errorMsg)[0]]) + "<br/>" + err?.response?.data?.exCode, "error");
            }
            else if (err.length > 0) {
                for (let i = 0; i < err.length; i++) {
                    let errorstr = "";
                    errorstr += err[i].Message + "<br/>";
                    self.notify(errorstr, "error");
                }
            }
            else {
                self.notify(self.$t("ConnectionError"), "error");
            }
        },
    },
    created: function () {
        var self = this;

    },
    mounted() {
        let self = this;
        self.loadLookups();

        this.$validator.localize(`${self.$i18n.locale}`, self.$i18n.locale == 'en' ? this.dictionary.en : this.dictionary.ar);
    }
};
